import React from 'react'

const Partners = () => {
  return (
    <div className="partners-section bg-dark alt">
      <div className="container">
        <ul className="partners-list">
          {/* <li>
            <img src="/images/humana-white.png" alt="Humana" />
          </li> */}
          {/* <li>
            <img src="/images/cigna-white.png" alt="Cigna" />
          </li> */}
          <li>
            <img src="/images/hii-white.png" alt="Health Insurance Innovations" />
          </li>
          <li>
            <img src="/images/health-markets-white.png" alt="Health Markets" />
          </li>
          <li>
            <img src="/images/ehealth-white.png" alt="eHealth" />
          </li>
          <li>
            <img src="/images/anthem-white.png" alt="Anthem" />
          </li>
          <li>
            <img src="/images/united-white.png" alt="United Healthcare" />
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Partners
