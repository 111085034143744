import React, { Component } from 'react'
import PropTypes from 'prop-types'

const testimonialList = [
  {
    img: '/images/test-1.jpg',
    quote:
      'Trying to understand the in’s and out’s of finding the right health insurance plan was a bit overwhelming for me. Being able to talk to the experts at Health Insurance Companion made the process a lot easier.',
    cite: 'Jenny J.',
  },
  {
    img: '/images/test-2.jpg',
    quote:
      'I was looking for a better health care plan but wanted to see the best rate I could get. I was able to get quotes within minutes and pick the plan I wanted.',
    cite: 'George Y.',
  },
  {
    img: '/images/test-3.jpg',
    quote:
      'I like how Health Insurance Companion was free to use and I could actually talk to a real person about how to get quotes from all the providers.',
    cite: 'Beverly D.',
  },
]

class Testimonials extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 0,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e, count) => {
    e.preventDefault()
    this.setState({ active: count })
  }

  renderDots = () => {
    let items = []
    let i = 0
    let count = 1
    const length = testimonialList.length
    while (i < length) {
      let buttonCount = i
      items.push(
        <li
          key={i}
          className={this.state.active === i ? 'slick-active' : ''}
          role="presentation"
        >
          <button
            type="button"
            role="tab"
            id="slick-slide-control00"
            aria-controls="slick-slide00"
            aria-label={`${count} of ${length}`}
            tabIndex="0"
            aria-selected="true"
            onClick={(e) => this.handleClick(e, buttonCount)}
          >
            {count}
          </button>
        </li>,
      )
      count++
      i++
    }

    return (
      <ul className="slick-dots" role="tablist">
        {items}
      </ul>
    )
  }

  render = () => (
    <div className="testimonial-section section">
      <div className="container">
        <div className="w-100 w-50-l mha-l">
          <h2>What are others saying about Health Insurance Companion?</h2>
          <div className="testimonial-slider alt">
            {testimonialList.map((item, i) => {
              return (
                <div
                  className={`item` + (i === this.state.active ? ` active` : '')}
                  key={i}
                >
                  <div className="testimonial-item">
                    <div className="img-holder circle">
                      <img src={item.img} alt={item.cite} width="200" height="200" />
                    </div>
                    <blockquote>
                      <q>{item.quote}</q>
                      <cite>{item.cite}</cite>
                    </blockquote>
                  </div>
                </div>
              )
            })}
            {this.renderDots()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
