import React from 'react'

import family from '../../../icons/family.svg'
import checklist from '../../../icons/checklist.svg'
import scales from '../../../icons/scales.svg'

const Benefits = () => {
  return (
    <div className="benefits-section alt">
      <div className="container">
        <h2 className="h2">
          30+ Health Insurance Companies. <br />
          All in One Place.
        </h2>
        <p>
          Lucky for you, Health Insurance Companion takes the hassle out of shopping for
          insurance.
        </p>
        <div className="boxes">
          <div className="box">
            <div className="content">
              <div className="icon">
                <img src={family} alt="Get Started" width="86" height="90" />
              </div>
              <h3>1. Get Started</h3>
              <p>
                Determine what’s most important to you or your family such as deductibles,
                co-pays and more.
              </p>
            </div>
          </div>
          <div className="box">
            <div className="content">
              <div className="icon">
                <img src={checklist} alt="Apply in 5 minutes" width="86" height="86" />
              </div>
              <h3>2. Apply in 5 minutes</h3>
              <p>
                Answer a few more questions to complete your application, and get the best
                quotes available.
              </p>
            </div>
          </div>
          <div className="box">
            <div className="content">
              <div className="icon">
                <img src={scales} alt="Compare Quotes" width="94" height="77" />
              </div>
              <h3>3. Compare Quotes</h3>
              <p>
                We value your time. Compare quotes and choose your fav, then check it off
                your list.
              </p>
            </div>
          </div>
        </div>
        <button
          href="#form-box"
          className="btn"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          Compare quotes
        </button>
      </div>
    </div>
  )
}

export default Benefits
