import React from 'react'

import stethoscope from '../../../images/stethoscope.png'

const UseInsurance = () => {
  return (
    <div className="use-insurance bg-light-blue section">
      <div className="container">
        <div className="w-50 w-100-s">
          <img src={stethoscope} alt="Should I Use Health Insurance Companion" />
        </div>
        <div className="w-50 w-100-s content">
          <h2>Should I use Health Insurance Companion?</h2>
          <p>
            Health insurance premiums are filed with and regulated by your state’s
            Department of Insurance. Whether you buy from Medicare Companion, your local
            agent, or directly from the health insurance company, you’ll pay the same
            monthly premium for the same plan. This means that you can enjoy the
            advantages and convenience of shopping and purchasing your health insurance
            plan through Health Insurance Companion and rest assured that you’re getting
            the best available price.
          </p>
        </div>
      </div>
    </div>
  )
}

export default UseInsurance
